import React, { useContext, useEffect, useState } from 'react';
import { Drawer, Affix, Button } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { processCkEditorHTML } from 'pwa/utils';
import styles from '../../styles/home/Topics.module.scss';
import TopicsSummary from './TopicsSummary';
import { FirebaseContext, UserContext } from '../../../contexts';
import { MediaCarousel } from '../../components';

const Topics = (props) => {
  const { data, moduleData, dataDrawer, showTopic, showQuiz } = props;
  const [title, setTitle] = useState('');
  const [currentProgress, setProgress] = useState(0);
  const [readProgress, setReadProgress] = useState();
  const [quizLandingDrawer, setQuizLandingDrawer] = useState(false);
  const [assignedModuleId, setAssignedModuleId] = useState();
  const [module, setModule] = useState();
  const firebase = useContext(FirebaseContext);
  const { user } = useContext(UserContext);
  const db = firebase.firestore();
  const max = data.length - 1;

  useEffect(async () => {
    /** To avoid data leakage if someone abruptly leaves the page
     * even before the data is loaded this will make sure
     * we are not calling any functions on unmounted component
     * */
    let isMounted = true;

    const fetchAssignedModulesData = async () => {
      const assignedModulesRef = await db.collection('assignedModules');
      const snapshotAssignedModules = await assignedModulesRef
        .where('moduleId', '==', moduleData.moduleId)
        .where('userId', '==', user.id)
        .get();

      if (!isMounted) {
        return;
      }

      setAssignedModuleId(snapshotAssignedModules.docs[0].id);
      await fetchModule(moduleData.moduleId);
      await checkReadProgress(moduleData.moduleId);
    };

    fetchAssignedModulesData();

    return () => {
      isMounted = false;
    };
  }, [title]);

  useEffect(async () => {
    if (assignedModuleId && readProgress >= 0) {
      await updateReadProgress();
    }
  }, [currentProgress, assignedModuleId, readProgress]);

  const fetchModule = async (topicModule) => {
    const modulesRef = await db.collection('modules');
    const snapshot = await modulesRef.where('id', '==', topicModule).get();
    const res = snapshot.docs[0].data();

    setModule(res);
    setTitle(res.title);
  };

  // check module tookquiz use onNavigate if possible
  // const fetchModuleName = async (topicModule) => {
  //   const modulesRef = await db.collection('modules');
  //   const snapshot = await modulesRef.where('id', '==', topicModule).get();
  //   const res = snapshot.docs[0].data();
  //   setTitle(res.title);
  // };

  const checkReadProgress = async (topicModule) => {
    const assignedModulesRef = await db.collection('assignedModules');
    const snapshot = await assignedModulesRef
      .where('moduleId', '==', topicModule)
      .where('userId', '==', user.id)
      .get();
    const res = snapshot.docs[0].data();
    setReadProgress(res.readProgress);
  };

  const updateReadProgress = async () => {
    console.log(
      'currentProgress: readProgress:',
      currentProgress,
      readProgress,
    );
    if (currentProgress + 1 > readProgress && assignedModuleId) {
      db.collection('assignedModules')
        .doc(assignedModuleId)
        .update({
          readProgress: currentProgress + 1,
        });
    }

    if (currentProgress >= max && assignedModuleId) {
      db.collection('assignedModules')
        .doc(assignedModuleId)
        .set({ readModule: true }, { merge: true });
    }
  };

  const renderMedia = () => {
    if ('media' in data[currentProgress]) {
      return <MediaCarousel data={data[currentProgress]} />;
    }
  };

  const renderPrevButton = () => {
    if (currentProgress > 0) {
      return (
        <div>
          <Affix style={{ position: 'fixed', bottom: 10, left: 15 }}>
            <Button
              className={styles.topicBtn}
              onClick={() => setProgress(currentProgress - 1)}
            >
              <LeftOutlined className={styles.arrow} />
            </Button>
          </Affix>
        </div>
      );
    }

    return null;
  };

  const renderNextButton = () => {
    if (currentProgress < max) {
      return (
        <div>
          <Affix style={{ position: 'fixed', bottom: 10, right: 15 }}>
            <Button
              className={styles.topicBtn}
              onClick={() => setProgress(currentProgress + 1)}
            >
              <RightOutlined className={styles.arrow} />
            </Button>
          </Affix>
        </div>
      );
    }
    return (
      <div>
        <Affix style={{ position: 'fixed', bottom: 10, right: 10 }}>
          <Button
            className={styles.topicBtn}
            onClick={() => setQuizLandingDrawer(true)}
          >
            <RightOutlined className={styles.arrow} />
          </Button>
        </Affix>
      </div>
    );
  };

  const QuizRender = () => {
    if (quizLandingDrawer === true) {
      return (
        <div>
          <TopicsSummary
            title={title}
            onClick={closeQuizDrawer}
            onClickCloseDrawers={closeDrawers}
            quizDrawer={dataDrawer}
            showTopic={showTopic}
            showQuiz={showQuiz}
            data={data}
            moduleData={moduleData}
            quizId={module?.quizId}
          />
        </div>
      );
    }

    return null;
  };

  const closeDrawers = (props) => {
    const { quizLandingDrawer } = props;
    setQuizLandingDrawer(quizLandingDrawer);
    dataDrawer(quizLandingDrawer);
  };

  const closeQuizDrawer = () => {
    setQuizLandingDrawer(false);
  };

  const closeThisDrawer = () => {
    dataDrawer(false);
  };

  const renderProgress = () => {
    let formatMax = max + 1;

    if (formatMax < 10) {
      formatMax = `0${formatMax}`;
    }
    if (currentProgress + 1 < 10) {
      return `0${currentProgress + 1} of ${formatMax}`;
    } else {
      return `${currentProgress + 1} of ${formatMax}`;
    }
  };

  const renderTopics = () => {
    return (
      <div className={styles.containerDrawer}>
        <div className={styles.topContainerDrawer}>
          <LeftOutlined
            onClick={() => closeThisDrawer()}
            className={styles.rightIconWhite}
          />
          <div>
            <div className={styles.titleText}>{title}</div>
            <div className={styles.progressText}>
              read progress • {renderProgress()}
            </div>
          </div>
        </div>
        <div className={styles.topicTitle}>{data[currentProgress].title}</div>
        <div>{renderMedia()}</div>
        <div className={styles.topicContent}>
          <div
            className={styles.htmlcontainer}
            dangerouslySetInnerHTML={{
              __html: processCkEditorHTML(data[currentProgress]?.content),
            }}
          />
          <Drawer
            placement="bottom"
            closable={false}
            onClose={() => setQuizLandingDrawer(false)}
            visible={quizLandingDrawer}
            height="100%"
            destroyOnClose
            // bodyStyle={{ backgroundColor: 'orange' }}
          >
            {QuizRender()}
          </Drawer>
        </div>
        <div>
          {renderPrevButton()}
          {renderNextButton()}
        </div>
      </div>
    );
  };
  return <div>{renderTopics()}</div>;
};
export default Topics;
