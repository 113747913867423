import { Button } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import {
  LeftOutlined,
  RightOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { MediaCarousel, Loading } from '../../components';
import styles from '../../styles/home/Quiz.module.scss';
import Percentage from '../../components/Percentage';
import Icon from '../../components/Icon';
import { FirebaseContext, UserContext } from '../../../contexts';
import { SHARED_CONFIG } from '../../../config/sharedConfig';

const Quiz = (props) => {
  const { moduleData, dataDrawer, type, data } = props;
  // DATADRAWER is a function handleOnDrawerClosed defined in TrainingCentre.js:185
  // change name to be more descriptive
  // MODULEDATA is basic data about module {companyId, id, moduleId}
  // TYPE is undefined for trainingCentre and 'briefingQuiz' for briefing Quiz
  // DATA is an array of topics to read (different for briefing assessment)

  const firebase = useContext(FirebaseContext);
  const { user } = useContext(UserContext);
  // the question number we are on
  const [currentProgress, setCurrentProgress] = useState(0);
  // title that is visible on top of the component
  const [title, setTitle] = useState();
  // if questions are finished show quiz results
  const [showQuizResults, setShowQuizResults] = useState();
  // first loading title, then array of questions
  const [quizInfo, setQuizInfo] = useState([
    { title: 'loading', quiz: [{ answer: 'loading', correctAnswer: false }] },
  ]);
  // questions we currently see on the page (selected or not)
  const [answersState, setAnswersState] = useState([{}]);
  // once we go to next page answersState is added to this array
  const [answers, setAnswers] = useState([]);
  // result number from 0 to 100
  const [grade, setGrade] = useState(0);
  // if no quiz render This section does not currently have an assessment.
  const [quizExist, setQuizExist] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // if result number is higher than quizPassingGrade render PASS
  const [quizPassingGrade, setQuizPassingGrade] = useState();
  const amountQuizzes = quizInfo.length;
  const db = firebase.firestore();
  // how is that not a state??
  let questionsWrong = [];

  useEffect(async () => {
    if (type === 'briefingQuiz') {
      setTitle('Briefing Assessment');
      let quizSnapshot = await db
        .collection('quiz')
        .where('eventId', '==', data.eventId)
        .get();
      if (quizSnapshot.docs.length > 0) {
        fetchQuizData(quizSnapshot.docs[0].data().id);
        setQuizPassingGrade(quizSnapshot.docs[0].data()?.passRate);
      }
    } else {
      await fetchModuleName(moduleData.moduleId);
    }
  }, []);

  useEffect(async () => {
    if (showQuizResults === true) {
      computeAnswers(answers);
    }
  }, [showQuizResults]);

  useEffect(() => {
    let mappedValues = quizInfo[currentProgress]?.quiz.map((item) => {
      return { data: item.answer, selected: false, key: item.key };
    });
    setAnswersState(mappedValues);
  }, [quizInfo[currentProgress]]);

  const quitHandle = () => {
    dataDrawer(false);
  };

  const handleBackToTraining = () => {
    dataDrawer(false);
    setShowQuizResults(false);
  };

  const resetQuiz = () => {
    setAnswers([]);
    setCurrentProgress(0);
    setShowQuizResults(false);
  };

  const fetchModuleName = async (topicModule) => {
    const modulesRef = await db
      .collection('modules')
      .where('id', '==', topicModule)
      .get();
    const res = modulesRef.docs[0].data();
    if (res.quizId) {
      setQuizExist(true);
    }
    fetchQuizData(res.quizId);
    setTitle(res.title);
  };

  const fetchQuizData = async (quizId) => {
    // We fetch this first to get the passing grade
    const mainQuizSnapshot = await db.collection('quiz').doc(quizId).get();
    setQuizPassingGrade(mainQuizSnapshot?.data()?.passRate);

    const quizRef = await db
      .collection('quiz')
      .doc(quizId)
      .collection('quiz')
      .orderBy('order')
      .get();
    let dataQuiz = [];
    quizRef.forEach((doc) => {
      dataQuiz.push(doc.data());
    });
    setQuizInfo(dataQuiz);

    if (dataQuiz.length === 0) {
      setQuizExist(false);
      setIsLoading(false);
    } else {
      setQuizExist(true);
      setIsLoading(false);
    }
  };

  const onClickAnswer = (index, value) => {
    answersState[index].selected = value;
    setAnswersState([...answersState]);
  };

  const handleOnNextButtonClick = () => {
    setAnswers([...answers, answersState]);
    currentProgress < amountQuizzes - 1
      ? setCurrentProgress(currentProgress + 1)
      : setShowQuizResults(true);
  };

  const computeAnswers = () => {
    const amountQuestions = quizInfo.length;
    const uniqueValues = [...new Set(questionsWrong)];
    const amountFailed = uniqueValues.length;
    let result = 0;

    if (amountFailed === 0) {
      result = 100;
    } else if (amountFailed === amountQuestions) {
      result = 0;
    } else {
      result = (amountFailed / amountQuestions) * 100;
      let reversePercentage = 100 - result;
      result = reversePercentage.toFixed(0);
    }
    setGrade(Number(result));
    const answersChosen = answers.map((questionAnswers, index) => {
      const questionId = quizInfo[index].id;
      const selectedAnswers = questionAnswers
        .filter((answer) => answer.selected)
        .map(({ key }) => key);
      return { questionId, selectedAnswers };
    });
    pushDataQuiz(answersChosen, result);
  };

  const pushDataQuiz = async (answersChosen, percentage) => {
    const quizObject = {
      tookQuiz: true,
      quizAnswers: answersChosen,
      grade: percentage,
    };
    const isPassed = percentage >= quizPassingGrade;

    if (isPassed) {
      quizObject.dateCompleted = new Date();
    }

    if (type === 'briefingQuiz') {
      await db
        .collection('assignedBriefingQuiz')
        .doc(data.id)
        .update(quizObject);
      console.log('Pushed Result!');
    } else {
      const assignedModulesRef = await db.collection('assignedModules');
      const assignedModules = await assignedModulesRef
        .where('moduleId', '==', moduleData.moduleId)
        .where('userId', '==', user.id)
        .get();

      const snapshotId = assignedModules.docs[0].id;
      const snapshot = assignedModulesRef.doc(snapshotId);

      snapshot.update(quizObject);
      console.log('Pushed Result!');
    }
  };

  const renderQuizResultPage = () => {
    return (
      <div className={styles.quizResultPageContainer}>
        <div className={styles.quizResultPageInnerContainer}>
          <Button className={styles.backToModule} onClick={() => resetQuiz()}>
            <Icon name="icon-refresh" size={20} className={styles.icon} />
          </Button>
          <div className={styles.titleText}>{title}</div>
          <div className={styles.resultText}>
            Results ● {renderResultText(grade)}
          </div>
          <div>
            <Percentage
              grade={grade}
              passingGrade={quizPassingGrade ?? SHARED_CONFIG.passingGrade}
              width={140}
            />
          </div>
          <div className={styles.divider} />
          <div className={styles.quizResult}>Answers</div>
          <div>{renderQuizResultContent()}</div>
          <div>
            <Button
              onClick={() => {
                handleBackToTraining();
              }}
              className={styles.goBackToTrainingCentre}
            >
              <LeftOutlined /> Back to training centre
            </Button>
          </div>
        </div>
        <div style={{ height: '125px' }} />
      </div>
    );
  };

  const renderQuizResultContent = () => {
    let questionCounter = 0;
    let rightAnswers = [];
    let questions = [];
    quizInfo.map((element) => rightAnswers.push(element.quiz));
    quizInfo.map((element) => questions.push(element.title));
    return questions.map((question) => {
      let answerCounter = 0;
      questionCounter++;
      return (
        <div>
          <div className={styles.quizAnsweredQuestion}>
            {questionCounter}. {question}
          </div>
          <div>
            {answers.length > 0
              ? answers[questionCounter - 1].map((element) => {
                  return (
                    <div>
                      {element.selected
                        ? checkAnswer(
                            question,
                            element.data,
                            element.key,
                            rightAnswers[questionCounter - 1],
                          )
                        : ''}
                    </div>
                  );
                })
              : 'no data'}
          </div>
          <div>
            {answers.length > 0
              ? rightAnswers[questionCounter - 1].map((res) => {
                  answerCounter++;
                  if (
                    res.correctAnswer &&
                    answers[questionCounter - 1][answerCounter - 1].selected ===
                      false
                  ) {
                    questionsWrong.push(question);
                    return (
                      <div className={styles.answerText}>
                        <b>{res.answer}</b>
                      </div>
                    );
                  } else if (
                    res.correctAnswer === false &&
                    answers[questionCounter - 1][answerCounter - 1].selected ===
                      false
                  ) {
                    {
                      return (
                        <div className={styles.answerText}>{res.answer}</div>
                      );
                    }
                  }
                })
              : ''}
          </div>
        </div>
      );
    });
  };

  const checkAnswer = (question, answer, key, rightAnswers) => {
    return rightAnswers.map((element) => {
      if (key === element.key) {
        if (element.correctAnswer) {
          return (
            <div className={styles.answerText}>
              {' '}
              <CheckCircleFilled /> {answer}
            </div>
          );
        } else {
          questionsWrong.push(question);
          return (
            <div className={styles.answerText}>
              {' '}
              <CloseCircleOutlined /> <del>{answer}</del>
            </div>
          );
        }
      }
    });
  };

  const renderResultText = (grade) => {
    let res = '';
    if (grade >= quizPassingGrade || grade >= SHARED_CONFIG.passingGrade) {
      res = (
        <span>
          PASS <CheckCircleFilled />
        </span>
      );
      return res;
    } else {
      res = (
        <span>
          FAIL <CloseCircleFilled />
        </span>
      );
      return res;
    }
  };

  const getProgress = () => {
    let formatMax = amountQuizzes;

    if (formatMax < 10) {
      formatMax = `0${amountQuizzes}`;
    }
    if (currentProgress + 1 < 10) {
      return `0${currentProgress + 1} of ${formatMax}`;
    } else {
      return `${currentProgress + 1} of ${formatMax}`;
    }
  };

  if (showQuizResults) {
    return renderQuizResultPage();
  }

  if (isLoading) {
    return (
      <div style={{ marginLeft: -24 }}>
        <Loading />
      </div>
    );
  }

  if (!quizExist) {
    return (
      <div className={styles.container}>
        <div className={styles.containerDrawer}>
          <div className={styles.topContainerDrawer}>
            <div className={styles.quizTitleText}>{title}</div>
            <div>
              <Button className={styles.quitBtn} onClick={quitHandle}>
                Quit
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.quizNotExist}>
          This section does not currently have an assessment.
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.containerDrawer}>
        <div className={styles.topContainerDrawer}>
          <div className={styles.quizTitleText}>{title}</div>
          <div className={styles.progressText}>
            Assessment progress • {getProgress()}
          </div>
          <div>
            <Button className={styles.quitBtn} onClick={quitHandle}>
              Quit
            </Button>
          </div>
        </div>
      </div>

      <div>
        <br />
        <br />
        {quizInfo[currentProgress]?.media && (
          <MediaCarousel data={quizInfo[currentProgress]} />
        )}
        <QuestionTitle title={quizInfo[currentProgress]?.title} />
        <Answers answersState={answersState} onClickAnswer={onClickAnswer} />
        {currentProgress < amountQuizzes && (
          <NextButton onClick={handleOnNextButtonClick} />
        )}
        <div style={{ height: 100 }} />
      </div>
    </div>
  );
};

function NextButton({ onClick }) {
  return (
    <div>
      <Button className={styles.quizBtn} onClick={onClick}>
        <RightOutlined className={styles.arrow} />
      </Button>
    </div>
  );
}

function QuestionTitle({ title }) {
  return (
    <div className={styles.quizContent}>
      <div>{title}</div>
    </div>
  );
}

function Answers({ answersState, onClickAnswer }) {
  return answersState?.map((element, index) => (
    <Button
      key={element.key}
      className={element.selected ? styles.selected : styles.unselected}
      style={{ marginTop: 20 }}
      defaultChecked={false}
      onClick={() => onClickAnswer(index, !element.selected)}
    >
      {element.data}
    </Button>
  ));
}

export default Quiz;
