import React from 'react';
import { Progress } from 'antd';
import styles from '../styles/components/Percentage.module.scss';

const Percentage = (props) => {
  const { grade, passingGrade, width } = props;
  let color = '';

  const checkGrade = () => {
    if (grade < passingGrade) {
      color = '#FF0000';
    } else {
      color = '#52C41A';
    }
  };
  checkGrade();
  return (
    <div className={styles.progressContent}>
      <Progress
        className={styles.progressCircle}
        type="circle"
        percent={grade}
        strokeColor={color}
        strokeWidth={5}
        width={width}
        format={(percent) => {
          return (
            <div>
              <div className={styles.numberPercentage} style={{ color: color }}>
                {percent}
                <div className={styles.percentage}> %</div>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default Percentage;
