export const MODULES = {
  briefing: 'Briefing',
  training: 'Training',
  scheduling: 'Scheduling',
  biostarIntegration: 'Biostar Integration',
};

export const COMPANY_PACKAGES = {
  briefingTraining: 'Briefing + Training',
  briefingTrainingScheduling: 'Briefing + Training + Scheduling',
  briefingTrainingSchedulingBiostar:
    'Briefing + Training + Scheduling + Biostar Integration',
};
