import { LoadingOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { useState } from 'react';
import styles from '../styles/Buttons.module.scss';
import Icon from './Icon';

export const PrimaryTextBtn = ({ title, icon, onClick, disabled }) => {
  return (
    <Button
      className={styles.primaryTextBtn}
      onClick={onClick}
      disabled={disabled}
    >
      <div style={{ marginRight: 8 }}>{title}</div>
      <div>
        <Icon name={icon} />
      </div>
    </Button>
  );
};

export const SmallTextBtn = ({ title, icon, onClick, disabled }) => {
  return (
    <Button
      className={styles.smallTextBtn}
      onClick={onClick}
      disabled={disabled}
    >
      <div style={{ marginRight: 8 }}>{title}</div>
      <div>
        <Icon name={icon} />
      </div>
    </Button>
  );
};

export const SubmitButton = ({ title, icon, onClick, loading, disabled }) => {
  return (
    <Button
      loading={loading}
      className={styles.largeSubmitButton}
      onClick={onClick}
      disabled={disabled}
    >
      <div style={{ color: 'black', marginRight: 8 }}>{title}</div>
      <div>
        <Icon name={icon} />
      </div>
    </Button>
  );
};

export const EventButton = ({ title, onClick, color, disabled, textColor }) => {
  return (
    <Button
      className={styles.eventAvailabilityBtn}
      onClick={onClick}
      style={{ backgroundColor: color, color: textColor }}
      disabled={disabled}
    >
      <div>{title}</div>
    </Button>
  );
};

export const RejectEventButton = ({
  title,
  onClick,
  color,
  disabled,
  textColor,
}) => {
  return (
    <Button
      className={styles.eventRejectBtn}
      onClick={onClick}
      style={{ backgroundColor: color, color: textColor }}
      disabled={disabled}
    >
      <div>{title}</div>
    </Button>
  );
};

export function LoadingLink({ text, onClick, ...props }) {
  const [isLoading, setIsLoading] = useState(false);

  async function handleClick() {
    setIsLoading(true);
    await onClick();
    setIsLoading(false);
  }

  return (
    <a
      className={styles.loadingLink}
      onClick={handleClick}
      disabled={isLoading}
      type="link"
      {...props}
    >
      {isLoading ? (
        <Spin spinning={isLoading} indicator={<LoadingOutlined />} />
      ) : (
        text
      )}
    </a>
  );
}

const ONE_MINUTE = 60 * 1000;
export function CooldownLink({
  text,
  onClick,
  timeBlocked = ONE_MINUTE,
  ...props
}) {
  const [isDisabled, setIsDisabled] = useState(false);

  async function handleClick() {
    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false);
    }, timeBlocked);
    await onClick();
  }

  return (
    <Button
      className={styles.cooldownLink}
      onClick={handleClick}
      disabled={isDisabled}
      type="link"
      // important for antd Tooltip to work properly
      {...props}
    >
      {text}
    </Button>
  );
}
