import { useState, useEffect, useContext } from 'react';
import { UserContext, FirebaseContext } from '../../../../contexts';

export function useEventNotificationStatus(eventId) {
  const [hasNewNotifications, setHasNewNotifications] = useState();
  const [notificationCount, setNotificationCount] = useState(0);
  const firebase = useContext(FirebaseContext);
  const db = firebase.firestore();
  const { user, refreshUser } = useContext(UserContext);

  useEffect(() => {
    const unsubscribeNotifications = db
      .collection('notifications')
      .where('eventId', '==', eventId)
      .onSnapshot(async (querySnapshot) => {
        // let userSnapshot = await db.collection('users').doc(user.id).get();
        // let userData = userSnapshot.data();

        let eventNotificationData = user?.notifications?.events?.filter(
          (item) => item.eventId === eventId,
        );
        let previousNotificationsCount =
          eventNotificationData?.length > 0 &&
          eventNotificationData[0]?.totalCount > 0
            ? eventNotificationData[0]?.totalCount
            : 0;
        if (
          querySnapshot.docs.length > 0 &&
          querySnapshot.docs.length > previousNotificationsCount
        ) {
          setNotificationCount(querySnapshot.docs.length);
          setHasNewNotifications(true);
        } else {
          setNotificationCount(querySnapshot.docs.length);
        }
      });

    return () => {
      unsubscribeNotifications();
    };
  }, []);

  const handleHasSeenNotification = async () => {
    setHasNewNotifications(false);
    let filteredEventNotifications = user?.notifications?.events?.filter(
      (item) => item.eventId !== eventId,
    );
    await db
      .collection('users')
      .doc(user.id)
      .update({
        notifications: {
          ...user?.notifications,
          events: user?.notifications?.events
            ? [
                ...filteredEventNotifications,
                { eventId, totalCount: notificationCount },
              ]
            : [{ eventId, totalCount: notificationCount }],
        },
      });
    refreshUser();
  };

  return [hasNewNotifications, handleHasSeenNotification];
}
