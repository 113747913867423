import { LeftOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { MODULES } from '../../../constants/companyPackagesConstants';
import { UserContext } from '../../../contexts';
import { isModuleInCompanyPackage } from '../../../utils';
import { EventNotifications, Icon } from '../../components';
import styles from '../../styles/home/EventOverview.module.scss';
import Briefing from './Briefing';
import Schedule from './Schedule';
import Training from './Training';
import { useEventNotificationStatus } from './hooks/useEventNotificationStatus';

const getDate = (date) => {
  return dayjs(date.toDate()).format('dddd, D MMMM YYYY [at] h:mm A');
};

const EventOverview = (props) => {
  const {
    onClose,
    data,
    defaultEventTab,
    // setToast,
    setSuspended,
    suspended,
    setSuspendedFromCompany,
    suspendedFromCompany,
    setIsavailable,
    setUnavailable,
    setRespondedEvent,
  } = props;

  const [currentTab, setCurrentTab] = useState('briefing');
  const [showNotification, setShowNotification] = useState();
  const [collapse, setCollapse] = useState(false);
  const [scrollsProgress, setScrollsProgress] = useState('0%');
  const [
    hasNewNotifications,
    handleHasSeenNotification,
  ] = useEventNotificationStatus(data?.eventId);

  useEffect(() => {
    if (defaultEventTab) {
      setCurrentTab(defaultEventTab);
    }
  }, [defaultEventTab]);

  useEffect(() => {
    if (suspendedFromCompany) {
      onClose();
    }
  }, [suspendedFromCompany]);

  useEffect(() => {
    if (suspended) {
      onClose();
    }
  }, [suspended]);

  const handleTabIconSelected = (tab) => {
    if (tab === currentTab) {
      return styles.tabIconSelected;
    }
    return styles.tabIcon;
  };

  const handleOnClickNotification = () => {
    handleHasSeenNotification();
    setShowNotification(true);
  };

  function handleScroll(event) {
    const content = event.target;
    // Check if we need to collapse the image
    if (!collapse && content.scrollTop > 0) {
      setCollapse(true);
    } else if (content.scrollTop === 0) {
      setCollapse(false);
    }

    // Calculate scroll
    const maximumScrollDistance = content.scrollHeight - content.clientHeight;
    const scrolledDistance = content.scrollTop;
    const percent = `${(scrolledDistance / maximumScrollDistance) * 100}%`;
    setScrollsProgress(percent);
  }

  return (
    <div className={styles.EventOverview}>
      <EventDetails
        onClose={onClose}
        data={data}
        handleOnClickNotification={handleOnClickNotification}
        hasNewNotifications={hasNewNotifications}
        collapse={collapse}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        handleTabIconSelected={handleTabIconSelected}
      />
      <div className={styles.eventContent} onScroll={handleScroll}>
        {currentTab === 'briefing' && (
          <Briefing
            eventId={data?.eventId}
            data={data}
            // setToast={setToast}
            onClose={onClose}
            setSuspended={setSuspended}
            setSuspendedFromCompany={setSuspendedFromCompany}
            setIsavailable={setIsavailable}
            setRespondedEvent={setRespondedEvent}
            setUnavailable={setUnavailable}
          />
        )}
        {currentTab === 'schedule' && (
          <Schedule data={data?.schedule} eventDate={data.eventDate} />
        )}
        {currentTab === 'training' && <Training data={data} />}
      </div>
      <ReadProgressBar scrollsProgress={scrollsProgress} />
      <Drawer
        placement="bottom"
        closable={false}
        visible={showNotification}
        height="100%"
        destroyOnClose
      >
        <NotificationContainer setShowNotification={setShowNotification}>
          <EventNotifications
            eventId={data?.eventId}
            eventImage={data?.eventImage}
          />
        </NotificationContainer>
      </Drawer>
    </div>
  );
};

function NotificationContainer({ children, setShowNotification }) {
  return (
    <div className={styles.notificationContainer}>
      <div
        className={styles.doneButton}
        onClick={() => setShowNotification(false)}
      >
        Done
      </div>
      <div className={styles.notificationContent}>{children}</div>
    </div>
  );
}

function EventDetails({
  onClose,
  data,
  handleOnClickNotification,
  hasNewNotifications,
  collapse,
  currentTab,
  setCurrentTab,
  handleTabIconSelected,
}) {
  const eventImageSource = data?.eventImage || './images/event-placeholder.svg';

  const eventImageClass = data?.eventImage
    ? `${styles.eventImage}`
    : `${styles.imagePlaceholder}`;

  return (
    <div
      className={
        collapse
          ? `${styles.EventDetails} ${styles.EventDetailsCollapse}`
          : styles.EventDetails
      }
    >
      <div className={styles.innerContainer}>
        <LeftOutlined className={styles.backIcon} onClick={onClose} />
        <div className={styles.eventInfo}>
          <div className={styles.title}>{data?.eventName}</div>
          <div className={styles.time}>{getDate(data?.eventDate)}</div>
        </div>
        <div className={styles.announcementIconContainer}>
          <Icon
            name="icon-megaphone"
            size={26}
            className={styles.announcementIcon}
            onClick={handleOnClickNotification}
          />
          {hasNewNotifications && <div className={styles.alertIndicator} />}
        </div>
      </div>

      <div
        className={
          collapse ? styles.imageCollapsedContainer : styles.imageContainer
        }
      >
        <img src={eventImageSource} className={eventImageClass} />
      </div>
      <TabNavigation
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        handleTabIconSelected={handleTabIconSelected}
      />
    </div>
  );
}

function TabNavigation({ currentTab, setCurrentTab, handleTabIconSelected }) {
  return (
    <div className={`${styles.TabNavigation} ${styles.shadow}`}>
      <div
        className={
          currentTab === 'briefing'
            ? `${styles.iconContainer} ${styles.selected}`
            : styles.iconContainer
        }
        onClick={() => setCurrentTab('briefing')}
      >
        <Icon
          name="icon-info"
          size={28}
          className={handleTabIconSelected('briefing')}
        />
      </div>
      <div
        className={
          currentTab === 'schedule'
            ? `${styles.iconContainer} ${styles.selected}`
            : styles.iconContainer
        }
        onClick={() => setCurrentTab('schedule')}
      >
        <Icon
          name="icon-three-o-clock-clock"
          size={28}
          className={handleTabIconSelected('schedule')}
        />
      </div>
      <div
        className={
          currentTab === 'training'
            ? `${styles.iconContainer} ${styles.selected}`
            : styles.iconContainer
        }
        onClick={() => setCurrentTab('training')}
      >
        <Icon
          name="icon-qa"
          size={28}
          className={handleTabIconSelected('training')}
        />
      </div>
    </div>
  );
}

function ReadProgressBar({ scrollsProgress }) {
  return (
    <div
      style={{
        width: '100%',
        position: 'absolute',
        bottom: 0,
        left: 0,
      }}
    >
      <div
        style={{
          background: '#00000033',
          width: '100%',
          height: 5,
          borderRadius: 2,
          marginBottom: -5,
        }}
      />
      <div
        style={{
          background: '#FCEA25',
          width: scrollsProgress || 0,
          height: 5,
          borderRadius: 2,
        }}
      />
    </div>
  );
}

export default EventOverview;
