import React, { useContext, useEffect, useState } from 'react';
import { LeftOutlined } from '@ant-design/icons';
import styles from '../../styles/home/EmptyTopicQuiz.module.scss';

const EmptyTopicQuiz = (props) => {
  const { topTitle, dataDrawer } = props;

  const closeThisDrawer = () => {
    dataDrawer(false);
  };

  const renderEmptyTopicQuiz = () => {
    return (
      <div className={styles.containerDrawer}>
        <div className={styles.topContainerDrawer}>
          <LeftOutlined
            onClick={() => closeThisDrawer()}
            className={styles.rightIconWhite}
          />
          <div>
            <div className={styles.titleText}>{topTitle}</div>
          </div>
        </div>
        <div className={styles.topicTitle}>Topic not available</div>
      </div>
    );
  };
  return <div>{renderEmptyTopicQuiz()}</div>;
};
export default EmptyTopicQuiz;