import { useState, useEffect } from 'react';
import moment from 'moment';
import styles from '../../styles/event-overview/Schedule.module.scss';
import { Icon } from '../../components';

const Schedule = (props) => {
  const { data, eventDate } = props;

  let result = data.reduce((total, currentValue, i) => {
    total[currentValue.day] = total[currentValue.day] || [];
    total[currentValue.day].push(currentValue);
    return total;
  }, {});

  const showFullDate = (day) => {
    let mDate = moment.unix(eventDate?.seconds).add(day, 'd');

    return mDate.format('dddd, D MMM');
  };

  return (
    <div className={styles.container}>
      {Object.entries(result)
        .sort((a, b) => a[0] - b[0])
        .map((val) => {
          let key = val[0];
          let scheduleData = val[1];
          return (
            <div>
              <div className={styles.scheduleDate}>
                {showFullDate(key)}
                <div className={styles.yellowLine} />
              </div>

              {scheduleData?.map((item, index) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                    }}
                  >
                    <div>
                      <div className={styles.circle} />
                      {index < scheduleData.length - 1 && (
                        <div className={styles.verticalLine} />
                      )}
                    </div>
                    <div className={styles.descriptionContainer}>
                      <div className={styles.time}>{item.time}</div>
                      <div className={styles.title}>{item.title}</div>
                      {item?.notes && (
                        <div className={styles.notesContainer}>
                          <Icon
                            name="icon-document"
                            size={16}
                            className={styles.notesIcon}
                          />
                          <div className={styles.notes}>{item?.notes}</div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
    </div>
  );
};

export default Schedule;
