const { notification } = require('antd');

/**
 * Executes a callback function and displays a warning notification if an error occurs.
 *
 * @param {Function} callback - The callback function to be executed.
 * @returns {Promise<void>} - A promise that resolves when the callback is complete.
 */
export async function withErrorNotification(callback) {
  try {
    await callback();
  } catch (error) {
    console.error(error);
    notification.warn({ description: error.message });
  }
}
