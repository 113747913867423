import { Input } from 'antd';
import { useRef, useState } from 'react';
import { processCkEditorHTML } from '../../utils';
import {
  EventButton,
  RejectEventButton,
} from '../../../admin/components/Buttons';
import { Icon } from '../../components';
import styles from '../../styles/event-overview/Briefing.module.scss';

export function WorkerAvailabilitySelection({
  user,
  handleAvailable,
  handleUnAvailable,
  handleReasonUnavailable,
  data,
}) {
  const reasonRef = useRef();
  const userIdRef = useRef();

  const [rejectReason, setRejectReason] = useState();
  const [required, setRequired] = useState();

  const SetRejectReasonTrue = () => {
    if (rejectReason) {
      const userId = userIdRef.current.value;
      if (userId) {
        handleReasonUnavailable(userId, 'Reason not provided by user');
        handleUnAvailable();
      }

      setRejectReason(false);
    } else {
      setRejectReason(true);
    }
  };

  const GetReasonRef = async (event) => {
    event.preventDefault();
    const reason = reasonRef.current.value;
    const userId = userIdRef.current.value;
    if (reason && userId) {
      handleReasonUnavailable(userId, reason);
      handleUnAvailable();
      reasonRef.current.value = '';
      userIdRef.current.value = '';
    } else {
      setRequired(true);
    }
  };

  const removeRequired = () => {
    setRequired(false);
  };

  return (
    <>
      <div className={`${styles.availabilityQuestion}`}>
        Are you available for this event?
      </div>
      <div className={styles.eventBtncontainer}>
        <EventButton
          title="Yes"
          onClick={handleAvailable}
          disabled={data.available.includes(user.id)}
          color="#52C41A"
        />

        <EventButton
          title="No"
          onClick={SetRejectReasonTrue}
          disabled={data.unAvailable.includes(user.id)}
          color="#FB4953"
        />
      </div>
      {rejectReason && (
        <>
          <div className={styles.overlay} />
          <div className={styles.modal}>
            <div className={styles.modalContent}>
              <h2 style={{ marginTop: '20px' }}>Reject event</h2>
              <h3>
                Please share the reason for declining the invitation to work
                this event
              </h3>
              <textarea
                className={styles.reason__text_area}
                style={{
                  border: required ? '2px solid red' : '2px solid #DADADA',
                }}
                placeholder="Your reason for declining (optional)...."
                ref={reasonRef}
                onFocus={removeRequired}
              />
              <input type="hidden" value={user.id} ref={userIdRef} />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  gap: '10px',
                  marginTop: '10px',
                }}
              >
                <RejectEventButton
                  title="Skip"
                  color="#EAEAEA"
                  textColor="#000"
                  onClick={SetRejectReasonTrue}
                />
                <RejectEventButton
                  title="Send"
                  color="#FCEA25"
                  textColor="#000"
                  onClick={GetReasonRef}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export function CollapsibleBriefing({
  topic,
  index,
  topicsList,
  setTopicsList,
  readTopic,
}) {
  return (
    <div key={topic?.key}>
      <div
        className={styles.topicContainer}
        onClick={async () => {
          const briefingsClone = JSON.parse(JSON.stringify(topicsList));
          briefingsClone[index].expand = !briefingsClone[index].expand;
          setTopicsList(briefingsClone);
          await readTopic(topic.briefingId, topic.key);
        }}
      >
        <div className={styles.title}>
          {topic.pdf === undefined ? (
            topic.title
          ) : (
            <a href={topic.pdf} download target="_blank" rel="noreferrer">
              {topic.title || 'View PDF'}
            </a>
          )}
        </div>

        {topicsList[index].expand ? (
          <Icon name="icon-cancel" size={16} className={styles.icon} />
        ) : (
          <Icon name="icon-plus" size={20} className={styles.icon} />
        )}
      </div>
      {topicsList[index].expand && (
        <div className={styles.briefingHtmlContainer}>
          <div
            className={styles.htmlcontainer}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: processCkEditorHTML(topic?.html),
            }}
          />
        </div>
      )}
      <div className={styles.divider} />
    </div>
  );
}

export function BriefingSearchInput({ display, handleOnSearch }) {
  return (
    <div
      className={styles.inputContainer}
      style={!display ? { marginTop: 20 } : {}}
    >
      <Input
        placeholder="Search briefing topics"
        prefix={
          <Icon name="icon-search-2" size={24} className={styles.searchIcon} />
        }
        onChange={handleOnSearch}
      />
    </div>
  );
}
