import React, { useState, useEffect, useContext } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styles from '../../styles/home/TopicsSummary.module.scss';
import Icon from '../../components/Icon';

const TopicsSummary = (props) => {
  const {
    title,
    onClick,
    onClickCloseDrawers,
    quizDrawer,
    showTopic,
    showQuiz,
    moduleData,
    quizId,
  } = props;

  return (
    <div className={styles.container}>
      <div className={styles.topSection}>
        <Button className={styles.backToModule} onClick={() => onClick(false)}>
          <Icon name="icon-refresh" size={20} className={styles.icon} />
        </Button>
        <div className={styles.titleText}>{title}</div>
        <div className={styles.readingText}>reading completed</div>

        {quizId && (
          <div className={styles.middleSection}>
            <div className={styles.takeQuizText}>
              Take the '{title}' assessment!
            </div>
            {/* <div>170 colleagues have completed it.</div> */}
            <Button
              className={styles.goToQuizButton}
              onClick={() => {
                showTopic(false);
                showQuiz(true);
                // onClickCloseDrawers(true);
                // quizDrawer(true);
              }}
            >
              <div style={{ display: 'flex' }}>
                <Icon name="icon-qa" size={20} className={styles.iconQuiz} />
                <div className={styles.takeQuizTextBlack}>
                  Take the assessment!
                </div>
              </div>
              <div className={styles.rightArrow}>
                <RightOutlined />
              </div>
            </Button>
          </div>
        )}
      </div>
      <div>
        <div className={styles.tailSection}>
          {/* <div className={styles.readText}>Read the next module</div>
          <Button className={styles.goToNextModule}>
            Next module <RightOutlined />
          </Button> */}
          <Button
            className={styles.backBtn}
            onClick={() => onClickCloseDrawers(false)}
          >
            <LeftOutlined /> Back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TopicsSummary;