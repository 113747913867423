import { useEffect, useState, useContext } from 'react';
import { Input, Drawer } from 'antd';
import { FirebaseContext, UserContext } from '../../../contexts';
import { Icon, TrainingBox, BriefingQuizBox } from '../../components';
import Topics from '../home/Topics';
import EmptyTopicQuiz from '../home/EmptyTopicQuiz';

import Quiz from '../home/Quiz';
import styles from '../../styles/event-overview/Training.module.scss';

const Training = ({ data }) => {
  const firebase = useContext(FirebaseContext);
  const db = firebase.firestore();
  const { user } = useContext(UserContext);

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [assignedModules, setAssignedModules] = useState([]);
  const [assignedBriefingQuiz, setAssignedBriefingQuiz] = useState();
  const [briefingQuizId, setBriefingQuizId] = useState();
  const [modules, setModules] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [topics, setTopics] = useState({ content: 'blabla' });
  const [moduleId, setModuleId] = useState();
  const [showTopic, setShowTopic] = useState();
  const [showQuiz, setShowQuiz] = useState();
  const [showBriefingQuiz, setShowBriefingQuiz] = useState();
  const [drawerColor, setDrawerColor] = useState();
  const [list, setList] = useState([]);

  const [activeTitle, setActiveTitle] = useState();

  useEffect(async () => {
    await fetchEventBriefingQuiz();
    await fetchAssignedModules();
    setIsFirstLoad(false);
  }, []);

  useEffect(() => {
    if (!showDrawer && !isFirstLoad) {
      fetchEventBriefingQuiz();
      fetchAssignedModules();
    }
  }, [showDrawer]);

  useEffect(() => {
    let holdObj = {};
    if (modules && assignedModules) {
      const tempAssg = Object.entries(assignedModules);
      modules.map((modval) => {
        tempAssg.map((assgVal) => {
          const tempobj = { ...modval };
          if (modval.id === assgVal[1].moduleId) {
            tempobj.record = assgVal;
            holdObj[modval.id] = tempobj;
          }
        });
      });
    }
    let finalArr = data.modules.map((i) => holdObj[i]);
    setList(finalArr);
  }, [modules, assignedModules]);

  const fetchEventBriefingQuiz = async () => {
    // First check if briefing quiz is created
    const briefingQuizSnapshot = await db
      .collection('quiz')
      .where('eventId', '==', data?.eventId)
      .get();

    if (briefingQuizSnapshot.docs.length > 0) {
      setBriefingQuizId(briefingQuizSnapshot?.docs[0]?.id);

      // Check if assigned briefing quiz exist
      const assignedBriefingQuizSnapshot = await db
        .collection('assignedBriefingQuiz')
        .where('eventId', '==', data?.eventId)
        .where('userId', '==', user.id)
        .get();

      if (assignedBriefingQuizSnapshot.docs.length > 0) {
        setAssignedBriefingQuiz(assignedBriefingQuizSnapshot.docs[0].data());
      } else {
        // If briefing quiz not yet assigned, assign to user
        let tempDoc = db.collection('assignedBriefingQuiz').doc();
        let obj = {
          id: tempDoc.id,
          companyId: data?.companyId,
          userId: user.id,
          eventId: data?.eventId,
          grade: null,
          quizAnswers: [],
          tookQuiz: false,
        };
        db.collection('assignedBriefingQuiz').doc(tempDoc.id).set(obj);
        setAssignedBriefingQuiz(obj);
      }
    }
  };

  const fetchAssignedModules = async () => {
    // First fetch valid published events
    let validEvents = [];
    const eventsSnapshot = await db
      .collection('events')
      .where('recruitedStaff', 'array-contains', user.id)
      .where('status', '==', 'published')
      .get();
    for (let item of eventsSnapshot.docs) {
      validEvents.push(item.data());
    }
    let validModuleIds = [];
    for (let event of validEvents) {
      validModuleIds.push(...event?.modules);
    }
    validModuleIds = [...new Set(validModuleIds)]; // get only unique values

    // Now fetch assigned modules and only retrieve valid modules to fetch later
    let finalAssignedModules = [];
    if (data?.modules.length > 0) {
      let assignedModulesRef = db.collection('assignedModules');
      const assignedModulesSnapshot = await assignedModulesRef
        .where('userId', '==', user.id)
        .where('moduleId', 'in', data?.modules)
        .get();
      assignedModulesSnapshot.forEach((doc) => {
        let assignedModulesdata = doc.data();

        if (validModuleIds.includes(assignedModulesdata.moduleId)) {
          finalAssignedModules.push(assignedModulesdata);
        }
      });
    }

    // fetch modules data
    let modulesData = await fetchModules(finalAssignedModules);
    setModules([...modulesData]);
    setAssignedModules([...finalAssignedModules]);
    // setIsLoading(false);
  };

  const fetchModules = async (finalAssignedModules) => {
    const moduleNameRef = await db.collection('modules');
    let data = [];
    for (let i = 0; i < finalAssignedModules.length; i++) {
      const moduleSnapshot = await moduleNameRef
        .where('id', '==', finalAssignedModules[i].moduleId)
        .get();
      if (moduleSnapshot.docs[0]) {
        let moduleData = moduleSnapshot.docs[0].data();

        // fetch and add topic length into modules
        let topicsSnapshot = await db
          .collection(`topics/${moduleData.topicId}/topics`)
          .get();
        moduleData.topicSize = topicsSnapshot.size;

        data.push(moduleData);
      }
    }
    return data;
  };

  const fetchTopic = async (topId) => {
    const topicIdRef = await db.collection('topics').doc(topId);

    const topicIdCollection = await db
      .collection('topics')
      .doc(topId)
      .collection('topics')
      .orderBy('order');

    const ctdata = [];
    const snapshot = await topicIdRef.get();
    const snapshotCollection = await topicIdCollection.get();
    snapshotCollection.forEach((doc) => {
      ctdata.push(doc.data());
    });

    setModuleId(snapshot.data());
    return ctdata;
  };

  const handleOnTrainingClick = async (props) => {
    const { topicId } = props;
    const topicss = await fetchTopic(topicId);
    setTopics(topicss);
    handleOnNavigate('gotoTopic');
    setShowDrawer(true);
  };

  const handleOnTrainingClickBot = async (props) => {
    const { topicId } = props;
    const topicss = await fetchTopic(topicId);
    setTopics(topicss);
    handleOnNavigate('gotoQuiz');
    setShowDrawer(true);
  };

  const handleOnNavigate = (text) => {
    if (text === 'gotoTopic') {
      setDrawerColor('white');
      setShowQuiz(false);
      setShowBriefingQuiz(false);
      setShowTopic(true);
    } else if (text === 'gotoQuiz') {
      setDrawerColor('white');
      setShowTopic(false);
      setShowBriefingQuiz(false);
      setShowQuiz(true);
    } else if (text === 'gotoBriefingQuiz') {
      setDrawerColor('white');
      setShowTopic(false);
      setShowQuiz(false);
      setShowBriefingQuiz(true);
    }
  };

  const handleGoToBriefingQuiz = () => {
    handleOnNavigate('gotoBriefingQuiz');
    setShowDrawer(true);
  };

  const renderDrawerContent = () => {
    if (showTopic === true && topics.length > 0) {
      // console.log(topics);
      return (
        <div>
          <Topics
            data={topics}
            moduleData={moduleId}
            showTopic={setShowTopic}
            showQuiz={setShowQuiz}
            dataDrawer={setShowDrawer}
          />
        </div>
      );
    }

    if (showQuiz === true && topics.length > 0) {
      return (
        <Quiz data={topics} moduleData={moduleId} dataDrawer={setShowDrawer} />
      );
    }

    if (showBriefingQuiz === true) {
      return (
        <Quiz
          data={assignedBriefingQuiz}
          type="briefingQuiz"
          dataDrawer={setShowDrawer}
        />
      );
    }

    return <EmptyTopicQuiz dataDrawer={setShowDrawer} topTitle={activeTitle} />;
  };

  const renderBriefingQuiz = () => {
    return (
      <div>
        <div className={styles.briefingQuizTitle}>BRIEFING ASSESSMENT</div>
        <BriefingQuizBox
          quizId={briefingQuizId}
          tookQuiz={assignedBriefingQuiz.tookQuiz}
          grade={assignedBriefingQuiz.grade}
          onClick={() => handleGoToBriefingQuiz()}
          onClickBot={() => handleGoToBriefingQuiz()}
        />
        <div className={styles.divider} />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {assignedBriefingQuiz && renderBriefingQuiz()}
      {list.length > 0 &&
        list.map((val) => {
          if (val) {
            return (
              <TrainingBox
                name={val?.title}
                topicId={val?.topicId}
                tookQuiz={val?.record[1].tookQuiz}
                quizId={val?.quizId}
                readProgress={val?.record[1].readProgress}
                grade={val?.record[1].grade}
                onClick={(topicId) => {
                  handleOnTrainingClick(topicId);
                  setActiveTitle(val?.title);
                }}
                onClickBot={(topicId) => handleOnTrainingClickBot(topicId)}
                topicSize={val?.topicSize}
              />
            );
          } else {
            return <div />;
          }
        })}
      <Drawer
        placement="bottom"
        closable={false}
        onClose={() => setShowDrawer(false)}
        visible={showDrawer}
        height="100%"
        destroyOnClose
        bodyStyle={{ backgroundColor: drawerColor }}
      >
        {renderDrawerContent()}
      </Drawer>
    </div>
  );
};

export default Training;
