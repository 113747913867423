import { message } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { COMPANY_PACKAGES } from '../../../constants/companyPackagesConstants';
import { FirebaseContext, UserContext } from '../../../contexts';
import { getBriefingsFirestore } from '../../../firebase/briefings';
import {
  addTopicToReadBriefing,
  getUserReadBreifingsFirestore,
  isTopicAlreadyRead,
  readTopicFirebase,
} from '../../../firebase/readBriefings';
import { withErrorNotification } from '../../../utils/errorHandling';
import { filterBriefingsByRole } from '../../../utils/rolesUtils';
import { Loading } from '../../components';
import styles from '../../styles/event-overview/Briefing.module.scss';
import {
  BriefingSearchInput,
  CollapsibleBriefing,
  WorkerAvailabilitySelection,
} from './BriefingMolecules';

const Briefing = (props) => {
  const {
    eventId,
    data: eventData,
    setIsavailable,
    setUnavailable,
    onClose,
    setRespondedEvent,
  } = props;

  const [topicsList, setTopicsList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [display, setDisplay] = useState();
  const [loading, setLoading] = useState(false);
  const firebase = useContext(FirebaseContext);
  const { user, companiesData } = useContext(UserContext);
  const key = 'updatable';
  const db = firebase.firestore();

  const [data, setData] = useState(eventData);
  const [readBriefings, setReadBriefings] = useState([]);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      const allBriefings = await getBriefingsFirestore(firebase, eventId);
      const briefingsFilteredByRole = filterBriefingsByRole(
        allBriefings,
        user,
        data.companyId,
      );
      const allTopics = briefingsFilteredByRole.flatMap((briefing) => {
        const briefingTopics = briefing.briefings;
        // Add briefingId to topic to reference it easily
        // since we are getting out of grouped structure
        return briefingTopics.map((topic) => ({
          ...topic,
          briefingId: briefing.id,
        }));
      });

      let publishedTopics = allTopics.filter(
        (item) => item.status === 'published',
      );
      setTopicsList(publishedTopics);
      setLoading(false);
    }

    const currentCompanyPackage = companiesData[data.companyId].package;

    if (
      data.acceptedStaff.includes(user.id) ||
      data.rejectedStaff.includes(user.id) ||
      currentCompanyPackage === COMPANY_PACKAGES.briefingTraining
    ) {
      setDisplay(false);
    } else {
      setDisplay(true);
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const fetchedReadBriefings = await getUserReadBreifingsFirestore(
        firebase,
        user.id,
        eventId,
      );
      setReadBriefings(fetchedReadBriefings);
    }
    fetchData();
  }, []);

  const handleOnSearch = (e) => {
    setSearchValue(e.target.value);

    topicsList.forEach((item) => {
      item.expand = false;
    });
  };

  async function readTopic(briefingId, topicId) {
    if (isTopicAlreadyRead(readBriefings, briefingId, topicId)) {
      return;
    }
    await withErrorNotification(() =>
      readTopicFirebase(
        firebase,
        user.id,
        eventData.companyId,
        briefingId,
        topicId,
        eventId,
      ),
    );
    const updatedReadBriefings = addTopicToReadBriefing(
      readBriefings,
      briefingId,
      topicId,
    );
    setReadBriefings(updatedReadBriefings);
  }

  const openMessage = (notification) => {
    message.success({
      content: notification,
      key,
      duration: 3,
      className: 'custom-class',
    });
  };

  async function fetchEvent() {
    let eventRef = await db.collection('events').doc(eventId).get();
    setData(eventRef.data());
    return eventRef.data();
  }

  const handleAvailable = async () => {
    try {
      const currentEventdata = await fetchEvent();
      let unAvailable = currentEventdata.unAvailable || [];
      if (unAvailable.length) {
        unAvailable = currentEventdata.unAvailable.filter(
          (id) => id !== user.id,
        );
      }
      let available = currentEventdata?.available || [];
      if (available.includes(user.id)) return;
      available.push(user.id);
      await db.collection('events').doc(eventId).set(
        {
          unAvailable,
          available,
        },
        { merge: true },
      );
      setRespondedEvent(currentEventdata.eventName);
      setIsavailable(true);
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleUnavailable = async () => {
    const currentEventData = await fetchEvent();
    let available = currentEventData.available || [];
    if (available.length) {
      available = currentEventData.available.filter((id) => id !== user.id);
    }
    let unAvailable = currentEventData?.unAvailable || [];
    if (unAvailable.includes(user.id)) return;
    unAvailable.push(user.id);
    db.collection('events')
      .doc(eventId)
      .set(
        {
          unAvailable,
          available,
        },
        { merge: true },
      )
      .then(() => console.log('success'))
      .catch((error) => {
        openMessage(error.response.message || 'failed');
      });
    setRespondedEvent(currentEventData.eventName);
    setUnavailable({ status: true });
    onClose();
  };

  const handleReasonUnavailable = async (userId, reason) => {
    const currentEventData = await fetchEvent();
    const unAvailableStaffReasonArray = currentEventData.unAvailableStaffReason || [];
    unAvailableStaffReasonArray.push({ userId, reason });
    const currentEventId = currentEventData.eventId;
    await firebase.firestore().collection('events').doc(currentEventId).update({
      unAvailableStaffReason: unAvailableStaffReasonArray,
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={styles.Briefing}>
      <div>
        {display && (
          <WorkerAvailabilitySelection
            user={user}
            handleAvailable={handleAvailable}
            handleUnAvailable={handleUnavailable}
            handleReasonUnavailable={handleReasonUnavailable}
            data={data}
          />
        )}
        <BriefingSearchInput
          display={display}
          handleOnSearch={handleOnSearch}
        />
        {topicsList
          ?.filter((item) => {
            return (
              item?.title?.toLowerCase().includes(searchValue?.toLowerCase()) ||
              item?.html?.toLowerCase().includes(searchValue?.toLowerCase())
            );
          })
          .map((topic, index) => (
            <CollapsibleBriefing
              topic={topic}
              index={index}
              topicsList={topicsList}
              setTopicsList={setTopicsList}
              // handleUpdateReadIndex={handleUpdateReadIndex}
              readTopic={readTopic}
            />
          ))}
      </div>
    </div>
  );
};

export default Briefing;
